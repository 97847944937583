
import GameManagerInstance from '@@core/GameManager';
import * as Phaser from 'phaser';

export type DropItemType = 'coin1' | 'coin5' | 'coin10' | 'flash';
export const DropItemTypeCount = 4;
export enum DropItemTypes {
  coin1 = 'coin1',
  coin5 = 'coin5',
  coin10 = 'coin10',
  flash = 'flash',
};

enum DropItemSpriteDataKeys {
  score = 'score',
  damage = 'damage',
  effect = 'effect',
}

export class DropItemSprite extends Phaser.Physics.Arcade.Sprite {
  private _itemType!: DropItemType;
  public get itemType(): DropItemType {
    return this._itemType;
  }

  public get damage(): number {
    return this.getData(DropItemSpriteDataKeys.damage);
  }

  public get score(): number {
    return this.getData(DropItemSpriteDataKeys.score);
  }

  public get effectAssetPath(): string {
    return this.getData(DropItemSpriteDataKeys.effect);
  }

  constructor(
    scene: Phaser.Scene,
    centerX: number,
    centerY: number,
    texture: string,
    itemType: DropItemType,
  ) {
    super(scene, centerX, centerY, texture);

    const gm = GameManagerInstance;
    const assets = gm.gameAssets;

    this._itemType = itemType;

    switch (itemType) {
      case 'coin1':
        this.setData(DropItemSpriteDataKeys.score, 1);
        this.setData(DropItemSpriteDataKeys.effect, assets.plus1);
        break;
      case 'coin5':
        this.setData(DropItemSpriteDataKeys.score, 5);
        this.setData(DropItemSpriteDataKeys.effect, assets.plus5);
        break;
      case 'coin10':
        this.setData(DropItemSpriteDataKeys.score, 10);
        this.setData(DropItemSpriteDataKeys.effect, assets.plus10);
        break;
      case 'flash':
        this.setData(DropItemSpriteDataKeys.damage, 1);
        break;
      default:
        break;
    }
  }
}
