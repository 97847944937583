import { ButtonHTMLAttributes, ReactNode } from "react";
import clsx from 'clsx';
import classes from './animateButton.module.scss';
import { Link } from "react-router-dom";

const LinkWrapper = (props: {
  link: string
  children: ReactNode;
  className?: string;
}) => {
  const { link, children, className } = props;

  return (
    <a
      className={className}
      target="_blank"
      href={link}
      rel="noreferrer noopener">
      {children}
    </a>
  )
};

interface AnimateButtonProps extends ButtonHTMLAttributes<{}> {
  templateColor?: 'orange' | 'ctbc-green'
  className?: string;
  wrapperClassName?: string;
  linkClassName?: string;
  reactLink?: string;
  blandLink?: string;
};

export const AnimateButton = (props: AnimateButtonProps) => {
  const {
    className,
    linkClassName,
    wrapperClassName,
    children,
    onClick,
    reactLink: link,
    blandLink,
    templateColor = 'orange',
  } = props;

  // let childrenWrapper = children;

  let button = (
    <button
      onClick={onClick}
      className={clsx(
        classes.animateButton,
        templateColor === 'ctbc-green' ? classes.templateCtbcGreen : '',
        className,
        )}>
      {children}
    </button>
  );

  if (blandLink) {
    button = (
      <LinkWrapper
        link={blandLink}
        className={clsx(
          linkClassName,
          classes.link,
        )}>
        {button}
      </LinkWrapper>
    )
  }

  if (link) {
    button = (
      <Link to={link} className={clsx(linkClassName, classes.link)}>
        {button}
      </Link>
    )
  }

  return (
    <div className={wrapperClassName}>
      {button}
    </div>
  )
};
