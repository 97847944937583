import { AnimateButton } from '@@components/animate-button/AnimateButton';
import Modal from '@@components/modal/Modal';

import clockImg from './clock.png';
import skullImg from './skull.png';

import classes from './instruction.module.scss';

const Instruction = () => {
  return (
    <Modal>
      <Modal.Header className={classes.head}>
        遊 戲 說 明
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <div className={classes.bodyText}>
          遊戲時間 60 秒，玩家必須在 60 秒內接到從天而降的貨幣。不同貨幣會獲得不同分數，如果接到 3 次閃電則遊戲結束。來看看你可以獲得幾分吧！
        </div>
        <div className={classes.bodyFooter}>
          <img className={classes.img} src={clockImg} alt="" />
          <img className={classes.img} src={skullImg} alt="" />
        </div>
      </Modal.Body>
      <AnimateButton
        reactLink='/ageMenu'
        className={classes.button}>
        我 暸 解 了
      </AnimateButton>
    </Modal>
  );
}

export default Instruction;
