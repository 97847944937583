
import { useEffect } from 'react';
import { AnimateButton } from '@@components/animate-button/AnimateButton';
import Modal from '@@components/modal/Modal';
import GameManagerInstance from '@@core/GameManager';

import star from './star.png';
import starGray from './star-gray.png';
import lineIcon from './line.png';
import facebookIcon from './facebook.png';
import headerImg from './game-over-header.png';

import classes from './gameOver.module.scss';

const scoreUBound = [30, 60, 90, 10000];
const scoreUBoundCount = scoreUBound.length;
const sharePrefixMessageTemplate = [
  '遊戲結束！你一共獲得了 {score} 分，',
  '遊戲結束！你一共獲得了 {score} 分，',
  '遊戲結束！你一共獲得了 {score} 分，',
  '遊戲結束！哇，你一共獲得了 {score} 分！',
];
const gameOverMessageTemplate = [
  '再加把勁吧！你不理財財不理你!',
  '理財新鮮人，繼續努力賺錢吧!',
  '不錯唷! 豐衣足食小資族!',
  '太厲害啦! 家財萬貫大富翁!',
];

const GameOver = (props: {
  onClose?: Function;
}) => {
  const {
    onClose = () => { },
  } = props;

  const gm = GameManagerInstance;
  const score = gm.score < 9999 ? gm.score : 9999;

  // 依據得分選擇分享的訊息
  const scoreRangeIndex = scoreUBound.findIndex(ubound => score < ubound);
  const fullShareMessage: string = `${sharePrefixMessageTemplate[scoreRangeIndex]
    .replace('{score}', score.toString())}${gameOverMessageTemplate[scoreRangeIndex]}`;

  const shareHtml = ['share1.html', 'share2.html', 'share3.html', 'share4.html'][scoreRangeIndex];
  const gamePath = `https://ctbcmuseum.info/${shareHtml}`;

  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${gamePath}&quote=${fullShareMessage}`;
  const lineUrl = `https://lineit.line.me/share/ui?url=${gamePath}&text=${fullShareMessage}`;

  useEffect(() => {
    return () => {
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal className={classes.root}>
      <Modal.Header className={classes.header}>
        <img className={classes.header__img} src={headerImg} alt="" />
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <div className={classes.body__stars}>
          {Array.from(Array(scoreRangeIndex + 1).keys()).map(x => (
            <img key={`star-${x}`} src={star} className={classes.body__stars__child} alt="" />
          ))}
          {Array.from(Array(scoreUBoundCount - (scoreRangeIndex + 1)).keys()).map(x => (
            <img key={`star-gray-${x}`} src={starGray} className={classes.body__stars__child} alt="" />
          ))}
        </div>
        <div className={classes.body__score}>
          <div className={classes.body__score__number}>{gm.score}</div>
          <div className={classes.body__score__suffix}>分</div>
        </div>
        <div className={classes.body__message}>{gameOverMessageTemplate[scoreRangeIndex]}</div>
        <div className={classes.body__buttonGroup}>
          <AnimateButton
            reactLink='/home'
            className={classes.body__buttonGroup__button}
            templateColor='ctbc-green'>
            再挑戰一次!
          </AnimateButton>
          <AnimateButton
            blandLink='https://www.ctbcmuseum.com'
            className={classes.body__buttonGroup__button}
            templateColor='ctbc-green'>
            認識中國信託文薈館
          </AnimateButton>
        </div>
      </Modal.Body>
      <Modal.Body className={classes.footer}>
        <div className={classes.footer__text}>結果分享至：</div>
        <div className={classes.footer__imgContainer}>
          <a href={fbUrl}
            className={classes.footer__imgContainer__link}
            title="Share on Facebook"
            target="_blank"
            rel="noreferrer noopener">
            <img className={classes.img} src={facebookIcon} alt="" />
          </a>
          <a href={lineUrl}
            className={classes.footer__imgContainer__link}
            title="Share on Line"
            target="_blank"
            rel="noreferrer noopener">
            <img className={classes.img} src={lineIcon} alt="" />
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default GameOver;
