
import clsx from "clsx";
import { PropsWithChildren } from "react";
import classes from './modal.module.scss'

type ModalBodyProps = PropsWithChildren<{
  className?: string;
}>;

export const ModalBody = (props: ModalBodyProps) => {
  const {
    children,
    className,
  } = props;
  return (
    <section className={clsx(classes.body, className)}>
      {children}
    </section>
  );
};
