import { GameInstance } from "@ion-phaser/react";
import { gameSize } from "./gameSize";
import * as Scenes from "@@src/core/scenes";

export const createPhaserConfig = (): GameInstance => {
  return {
    width: gameSize.width,
    height: gameSize.height,
    type: Phaser.AUTO,
    scale: {
      mode: Phaser.Scale.HEIGHT_CONTROLS_WIDTH,
      // mode: Phaser.Scale.ENVELOP,
      autoCenter: Phaser.Scale.CENTER_HORIZONTALLY,
    },
    autoFocus: true,
    render: {
      antialias: true,
      pixelArt: true,
      roundPixels: true,
      transparent: true,
    },
    physics: {
      default: 'arcade',
      arcade: {
        gravity: { y: 400 },
        debug: false
      }
    },
    scene: [Scenes.PreloadScene, Scenes.MainScene],
  };
};
