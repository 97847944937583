import clsx from 'clsx';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './footer.module.scss';

const Footer = () => {
  const isPrivacy = useLocation().pathname === '/privacy';
  const [displayCopyright, setDisplayCopyright] = useState(false);

  return (
    <>
      <footer className={classes.root}>
        <div className={classes.text}>
          <Link to="/privacy" className={clsx(classes.linkWithAnimate, isPrivacy ? classes.linkActive : '')}>
            隱私權保護政策
          </Link>
          <span className={classes.divider}>|</span>
          <a className={classes.linkWithAnimate} href="https://www.ctbcmuseum.com/zh_TW">
            © 中國信託 版權所有
          </a>
        </div>
        <div className={classes.text}>
          <span className={clsx(
            classes.linkWithAnimate,
            displayCopyright ? classes.linkActive : ''
          )} onClick={() => {
            setDisplayCopyright(x => !x);
          }}>
            Music: Old MacDonald by The Green Orbs
          </span>
          {displayCopyright && (
            <div className={classes.floatBox}>
              <div className={classes.floatBox__Sharp} />
              <span>Old MacDonald by The Green Orbs</span><br />
              <span className={classes.floatBox__body2m}>
                from YouTube Audio Library:<br />
                <a
                  className={classes.floatBox__link}
                  href="https://www.youtube.com/watch?v=gwbDGNQ2dro"
                  target="_blank"
                  rel="noreferrer noopener">
                  https://www.youtube.com/audiolibrary_...<br />
                </a>
                Other background music of this artist:<br />
              </span>
              <br />
              <span>YouTube Audio Library - Follow Us</span><br />
              <span className={classes.floatBox__body2m}>
                YouTube:     https://goo.gl/Z6TsBI<br />
                Facebook:   https://goo.gl/ikoMdk<br />
                Google+:      https://goo.gl/90orLC<br />
                Instagram:   https://goo.gl/lLAoUo<br />
              </span>
            </div>
          )}
        </div>
      </footer>
      {displayCopyright && <div className={classes.floatBoxBg} onClick={() => setDisplayCopyright(false)} />}
    </>
  );
};

export default Footer;
