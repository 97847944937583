import { SceneKeys } from "./scenes";
import { gameSize } from "./gameSize";
import { GameAge } from "./gameAge";
import { gameAgesAssets } from "./gameAssets";

class GameManager {
  private static _instance: undefined | GameManager;
  private constructor() { }
  public static getSingleton() {
    if (!GameManager._instance) {
      GameManager._instance = new GameManager();
    }

    return GameManager._instance;
  }


  public get gameAssets() { return gameAgesAssets[this.age]; }
  /** 將所有素材作為image載入, 請在此之前載入player的spritesheet */
  public loadGameAssets = (thisFromSceneInstance: Phaser.Scene) => {
    const assets = this.gameAssets;

    Object.values(assets).forEach((value: string) => {
      if (value.match(/-sheet.png$/)) {
        return;
      } else if (value.match(/.png$/)) {
        thisFromSceneInstance.load.image(value, value);
      } else if (value.match(/.mp3$/)) {
        thisFromSceneInstance.load.audio(value, value);
      }
    });
  }

  private _worldSize = gameSize;
  public get worldSize() { return this._worldSize; }

  private _scene: SceneKeys = SceneKeys.PreloadScene;
  public get scene(): SceneKeys {
    return this._scene;
  }
  public set scene(v: SceneKeys) {
    this._scene = v;
  }

  // public volume: number = 0;
  public readonly subscribeVolume: Function[] = [];
  private _volume: number = 0;
  public get volume(): number {
    return this._volume;
  }
  public set volume(v: number) {
    this._volume = v;
    this.subscribeScore.forEach(item => item(v));
  }

  // public readonly subscribeChangeScene: Function[] = [];
  /** return true if changed */
  public changeScene = (thisScene: Phaser.Scene, callback?: Function) => {
    if (this.scene !== thisScene.scene.key) {
      callback?.(thisScene.scene.key, this.scene);
      thisScene.scene.stop();
      thisScene.scene.start(this.scene);
      return true;
    }
    return false;
  }

  public readonly subscribeGameOver: Function[] = [];
  gameOver(isGameOver?: boolean) {
    this.scene = SceneKeys.PreloadScene;
    this.subscribeGameOver.forEach(item => item(isGameOver ?? true));
  }

  public readonly subscribeScore: Function[] = [];
  private _score: number = 0;
  public get score(): number { return this._score; }
  public set score(value: number) {
    this._score = value;
    this.subscribeScore.forEach(item => item(value));
  }

  public readonly subscribeAge: Function[] = [];
  private _age = GameAge.Modern;
  public get age() { return this._age; }
  public set age(value: GameAge) {
    this._age = value;
    this.subscribeAge.forEach(item => item(value));
  }

  public readonly subscribeHp: Function[] = [];
  private _hp = 3;
  public get hp(): number {
    return this._hp;
  }
  public set hp(value: number) {
    this._hp = value;
    if(this._hp <= 0) {
      this._hp = 0;
      this.gameOver();
    }
    this.subscribeHp.forEach(item => item(value));
  }

  public readonly subscribeRemainingSecond: Function[] = [];
  private _remainingSecond: number = 60;
  public get remainingSecond() : number {
    return this._remainingSecond;
  }
  public set remainingSecond(value : number) {
    this._remainingSecond = value;
    if(this._remainingSecond <= 0) {
      this._remainingSecond = 0;
      this.gameOver();
    }
    this.subscribeHp.forEach(item => item(value));
  }

  public newGame = (age?: GameAge) => {
    this.age = age ?? this.age;
    this.score = 0;
    this.hp = 3;
    this._remainingSecond = 60;
  }
}

const GameManagerInstance = GameManager.getSingleton();
export default GameManagerInstance;
