import Modal from '@@components/modal/Modal';
import { useLocation } from 'react-router-dom';

import GameOverHeader from '@@pages/game-over/game-over-header.png';

import titleImg from './title.png';

import classes from './share.module.scss'

const scoreUBound = [30, 60, 90, 10000];

const gameOverMessageTemplate = [
  ['再加把勁吧！' ,'你不理財財不理你!'],
  ['理財新鮮人，' ,'繼續努力賺錢吧!'],
  ['不錯唷! ', '豐衣足食小資族!'],
  ['太厲害啦!', '家財萬貫大富翁!'],
];

const Share = (props: {
  scorePrefix: string;
}) => {
  const {
    scorePrefix,
  } = props;

  const scoreCode = useLocation().pathname.substring(scorePrefix.length);

  const score = parseInt(scoreCode, 10) > 10000 ? 9999 : parseInt(scoreCode, 10);

  // 依據得分選擇分享的訊息
  const scoreRangeIndex = scoreUBound.findIndex(ubound => score < ubound);

  return (
    <Modal className={classes.modalFront}>
      <img className={classes.titleLogo} src={titleImg} alt="" />
      <div className={classes.gameOverContainer}>
        <Modal.Header className={classes.header}>
          <img className={classes.header__img} src={GameOverHeader} alt="" />
        </Modal.Header>
        <Modal.Body className={classes.body}>
          {gameOverMessageTemplate[scoreRangeIndex].map(text => (
            <div key={text} className={classes.body__message}>{text}</div>
          ))}
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default Share;
