import { AnimateButton } from '@@components/animate-button/AnimateButton';
import Modal from '@@components/modal/Modal';
import { GameAge } from '@@core/gameAge';
import GameManagerInstance from '@@core/GameManager';
import classes from './ageMenu.module.scss'

const AgeMenu = () => {
  const gm = GameManagerInstance;

  return (
    <Modal>
      <Modal.Header className={classes.head}>
        選擇一個場景挑戰吧！
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <div className={classes.buttonGroup}>
          <AnimateButton
            reactLink='/ageDescription'
            className={classes.ageButton}
            onClick={() => { gm.age = GameAge.Holocene }}>
            史 前 時 代
          </AnimateButton>
          <AnimateButton
            reactLink='/ageDescription'
            className={classes.ageButton}
            onClick={() => { gm.age = GameAge.Postclassical }}>
            古 代
          </AnimateButton>
          <AnimateButton
            reactLink='/ageDescription'
            className={classes.ageButton}
            onClick={() => { gm.age = GameAge.Modern }}>
            現 代
          </AnimateButton>
          <AnimateButton
            reactLink='/ageDescription'
            className={classes.ageButton}
            onClick={() => { gm.age = GameAge.Future }}>
            未 來 虛 擬 世 界
          </AnimateButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AgeMenu;
