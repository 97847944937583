import {
  useRef,
  useState,
} from 'react';
import { AnimateButton } from '@@components/animate-button/AnimateButton';
import GameManagerInstance from '@@core/GameManager';
import soundOn from './sound-on.png';
import soundOff from './sound-off.png';
import bgm from './bgm.mp3';
import classes from './soundButton.module.scss'

export function SoundButton() {
  const gm = GameManagerInstance;
  const [sound, setSound] = useState(false);
  const soundButtonRef = useRef<HTMLAudioElement>(null);

  return (
    <>
      <AnimateButton
        templateColor='ctbc-green'
        onClick={() => {
          const newState = !sound;
          if (newState) {
            var promise = soundButtonRef.current?.play();
            if (promise !== undefined) {
              promise.then(_ => {
                // Autoplay started!
                gm.volume = 0.2;
                if (soundButtonRef.current) {
                  soundButtonRef.current.volume = 0.2;
                }
                setSound(true);
              }).catch(error => {
                // Autoplay was prevented.
                // Show a "Play" button so that user can start playback.
                // setSound(false);
                // TODO: 顯示音效啟動失敗的提示訊息？
              });
            }
          } else {
            setSound(false);
            soundButtonRef.current?.pause();
            gm.volume = 0;
          }
        }}
        className={classes.soundButton}>
        <img className={classes.soundButton__img} src={sound ? soundOn : soundOff} alt="" />
      </AnimateButton>

      <audio ref={soundButtonRef} src={bgm} controls={false} muted={false} loop={true} autoPlay={false} />
    </>
  );
}

