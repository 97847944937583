
import { AnimateButton } from '@@components/animate-button/AnimateButton';
import Modal from '@@components/modal/Modal';
import { MuseumColor } from '@@themes/color';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import titleImg from './title-black.png';

import classes from './home.module.scss'

const Home = (props: { setIsHomePage: Function }) => {
  const { setIsHomePage } = props;

  useEffect(() => {
    setIsHomePage(true);
    return () => {
      setIsHomePage(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal color={MuseumColor['--museum-color-white']}>
      <div className={classes.root}>
        <img className={classes.img} src={titleImg} alt="" />
        <Link to="/instruction">
          <AnimateButton
            className={classes.button}
            templateColor='ctbc-green'>
            遊 戲 開 始
          </AnimateButton>
        </Link>
      </div>
    </Modal>
  );
}

export default Home;
