
import GameManagerInstance from '@@core/GameManager';
import { GameInstance, IonPhaser } from '@ion-phaser/react'
import { useEffect, useRef, useState } from 'react';
import { createPhaserConfig } from '../../core/phaserConfig';

import { GameBackground } from './GameBackground';
import { GameHud } from './GameHud';
import classes from './game.module.scss'

const Game = (props: { gaming: boolean }): JSX.Element => {
  const { gaming } = props;
  const gm = GameManagerInstance;
  const gameRef = useRef<HTMLIonPhaserElement>(null);
  const [game, setGame] = useState<GameInstance>();

  useEffect(() => {
    setGame(createPhaserConfig());

    gm.subscribeVolume.push((volume: number) => {
      if (volume > 0) {
        const audio = gameRef.current?.game?.audio;
        if (audio) {
          audio.context = new AudioContext();
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={classes.root}>
      <GameBackground className={classes.gameBg} />
      <IonPhaser
        className={classes.phaser}
        ref={gameRef}
        game={game} />
      {gaming &&  <GameHud />}
    </section>
  );
};

export default Game;
