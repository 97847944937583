import { GameAge } from "./gameAge";

interface GameAssets {
  background: string;
  coin1: string;
  coin5: string;
  coin10: string;

  ground: string
  player: string;
  plus1: string;
  plus5: string;
  plus10: string;
  lightning: string;

  dropItemSound1: string;
  lightningSound1: string;
  gameOverSound: string;
}

const shareAssets = {
  ground: "/assets/ground.png",
  player: '/assets/char-sheet.png',
  plus1: '/assets/plus1.png',
  plus5: '/assets/plus5.png',
  plus10: '/assets/plus10.png',
  lightning: '/assets/flash.png',

  dropItemSound1: '/assets/coin01.mp3',
  lightningSound1: '/assets/flash.mp3',
  gameOverSound: '/assets/gameOver.mp3',
}

// Note: sprite sheet 需在副檔名前加上 -sheet (ex: 'char-sheet.png')
export const gameAgesAssets: Record<GameAge, GameAssets> = {
  [GameAge.Holocene]: {
    background: '/assets/bg1.png',
    coin1: '/assets/egg.png',
    coin5: '/assets/meat.png',
    coin10: '/assets/shell.png',

    ...shareAssets,
  },
  [GameAge.Postclassical]: {
    background: '/assets/bg2.png',
    coin1: '/assets/coin.png',
    coin5: '/assets/gold.png',
    coin10: '/assets/ancient-bill.png',

    ...shareAssets,
  },
  [GameAge.Modern]: {
    background: '/assets/bg3.png',
    coin1: '/assets/50dollar.png',
    coin5: '/assets/credit-card.png',
    coin10: '/assets/1000dollar.png',

    ...shareAssets,
  },
  [GameAge.Future]: {
    background: '/assets/bg4.png',
    coin1: '/assets/bitcoin.png',
    coin5: '/assets/line-point.png',
    coin10: '/assets/phone.png',

    ...shareAssets,
  },
}
