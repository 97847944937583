
const baseWidth = 2880; // 1485 * 2;
const baseHeight = 1672; // 836 * 2;

export const gameSize = {
  width: baseWidth,
  height: baseHeight,
  centerX: baseWidth / 2,
  centerY: baseHeight / 2,
}
