import { GameAge } from "@@core/gameAge";
import GameManagerInstance from "@@core/GameManager";
import { CSSProperties, useEffect, useState } from "react";

const backgroundOffset: Record<GameAge, string> = {
  // src width: 1059
  // mobile width: 375
  // base = 1052 - 375 = 684

  Holocene: '50%',
  Postclassical: '76%', // left:521/684
  Modern: '11.7%', // left:80/684
  Future: '50%'
}

export const GameBackground = (props: {
  className: string
}) => {
  const { className } = props;
  const gm = GameManagerInstance;
  const assets = gm.gameAssets;

  const [, forceRerender] = useState(0);

  const bgSrc = assets.background;
  const imgStyle: CSSProperties & Record<`--${string}`, string> = {
    '--game-background-pos-x': backgroundOffset[gm.age],
  };

  useEffect(() => {
    gm.subscribeAge.push(() => forceRerender(x => (x + 1) % 11));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <img className={className} style={imgStyle} src={bgSrc} alt="" />
  );
};
