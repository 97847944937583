import GameManagerInstance from '@@core/GameManager';
import { DropItemSprite, DropItemTypeCount, DropItemTypes } from '@@core/sprites/DropItem';
import * as Phaser from 'phaser';
import { SceneKeys } from '.';

// 素材縮放比 / 背景縮放比 = 還原成設計稿中物體與背景的比例
const dropItemsMobileScaleRate = 0.333 * 1.5;
const playerMobileScaleRate = 0.384 * 1.5;
const scorePlusMobileScaleRate = 0.5818 * 1.5;

export class MainScene extends Phaser.Scene {
  private curScalingMode: 'mobile' | 'desktop' = 'desktop';

  private rnd = Phaser.Math.RND;
  private platforms!: Phaser.Physics.Arcade.StaticGroup;

  // TODO: refactor Sprite lifecycle (SpriteBase)
  // TODO: refactor player
  private player!: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
  private dropItems!: Phaser.Physics.Arcade.Group;
  private plusImage!: Record<number, string>;

  private itemKeys!: string[];

  // -- input --
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  private keyboard: {
    A: Phaser.Input.Keyboard.Key | undefined,
    D: Phaser.Input.Keyboard.Key | undefined,
    Del: Phaser.Input.Keyboard.Key | undefined,
    Backspace: Phaser.Input.Keyboard.Key | undefined,
    LastIsDownDel?: boolean,
    LastIsDownBackspace?: boolean,
  } = { A: undefined, D: undefined, Del: undefined, Backspace: undefined};

  // timer
  // NOTE: 切換 scene 或釋放 Phaser 時是否需手動釋放 timer?
  private createCoinTimer!: Phaser.Time.TimerEvent;
  private remainingTimer!: Phaser.Time.TimerEvent;

  /** var */
  private boundLeft = 0;
  private boundRight = 0;
  private readonly groundShift = 70;
  private readonly playerBaseHeight = 621;

  /** helper */

  // 畫面中的地圖碰撞邊界對應的遊戲內X
  private updateBoundRange() {
    const canvasScaleRate = this.scale.gameSize.width / this.scale.canvasBounds.width;
    const canvasOutsideShift = Math.max(-(this.scale.canvasBounds.x), 0);
    this.boundLeft = canvasOutsideShift * canvasScaleRate;
    this.boundRight = (this.scale.canvasBounds.width - canvasOutsideShift) * canvasScaleRate;
  }

  private updateCamera() {
    const offsetY = this.scale.displaySize.height - this.scale.parentSize.height;
    this.cameras.main.scrollY = offsetY * this.scale.displayScale.y;
  }

  // TODO: refactor 獨立出 playerSprite
  private playerDirect: 'left' | 'right' = 'right';
  private playerDamageAnimsTimeMs = 750;
  private playerDamageTimeMs = Date.now() - this.playerDamageAnimsTimeMs;
  private updatePlayer() {
    const pointer = this.input?.activePointer;
    const isTouchLeft = pointer?.isDown && this.player?.x - 33 > pointer?.x;
    const isTouchRight = pointer?.isDown && this.player?.x + 33 < pointer?.x;
    if ((this.cursors.left.isDown || this.keyboard.A?.isDown || isTouchLeft)
      && this.player.x >= this.boundLeft + this.player.displayWidth / 2) {
      this.player.setVelocityX(-960);
      this.playerDirect = 'left';
    }
    else if ((this.cursors.right.isDown || this.keyboard.D?.isDown || isTouchRight)
      && this.player.x <= this.boundRight - this.player.displayWidth / 2) {
      this.player.setVelocityX(960);
      this.playerDirect = 'right';
    }
    else {
      this.player.setVelocityX(0);
    }

    const isDamageState = Date.now() - this.playerDamageTimeMs <= this.playerDamageAnimsTimeMs;

    // update anims
    if (this.playerDirect === 'left') {
      this.player.anims.play(isDamageState ? 'left2' : 'left', true);
    }
    if (this.playerDirect === 'right') {
      this.player.anims.play(isDamageState ? 'right2' : 'right', true);
    }
  }

  // 即時調整畫面中的物體縮放比
  private updateScaling() {
    if (window.innerWidth < 880 && this.curScalingMode === 'desktop') {
      this.player.scale = playerMobileScaleRate;
      this.dropItems.scaleXY(dropItemsMobileScaleRate - 1);
      this.curScalingMode = 'mobile';

      const newPlayerHeight = this.playerBaseHeight * playerMobileScaleRate / 2;
      this.player.y = this.scale.baseSize.height - newPlayerHeight - this.groundShift;
    } else if (window.innerWidth >= 880 && this.curScalingMode === 'mobile') {
      this.player.scale = 1;
      this.dropItems.scaleXY(1 - dropItemsMobileScaleRate);
      this.curScalingMode = 'desktop';

      const newPlayerHeight = this.playerBaseHeight / 2;
      this.player.y = this.scale.baseSize.height - newPlayerHeight - this.groundShift;
    }
  }

  createScorePlusAnimation(value: number, centerX: number, centerY: number) {
    const textureKey = this.plusImage[value] as string;
    const plusScore = this.add.image(centerX, centerY, textureKey);
    if (this.curScalingMode === 'mobile') {
      plusScore.scale = scorePlusMobileScaleRate;
    }

    this.tweens.add({
      targets: plusScore,
      duration: 800,
      y: centerY - 200,
      alpha: 0,
      ease: 'Power0', // 'Linear',
    });
  }

  createDropItem() {
    const itemTypeIndex = this.rnd.integer() % DropItemTypeCount;

    // TODO 提供config設定比例
    const itemPool = [
      DropItemTypes.coin1,
      DropItemTypes.coin5,
      DropItemTypes.coin10,
      DropItemTypes.flash,
    ];

    const dropItemHalf = 144; // maximum dropItem width / 2

    const newSprite = new DropItemSprite(
      this,
      this.rnd.realInRange(this.boundLeft + dropItemHalf, this.boundRight - dropItemHalf),
      -100,
      this.itemKeys[itemTypeIndex],
      itemPool[itemTypeIndex]
    );
    if (this.curScalingMode === 'mobile') {
      newSprite.scale = dropItemsMobileScaleRate;
    }

    this.add.existing(newSprite);
    this.dropItems?.add(newSprite);
  }

  gameOver() {
    const gm = GameManagerInstance;
    const assets = gm.gameAssets;
    this.playSound(assets.gameOverSound);
    GameManagerInstance.gameOver();
  }

  playSound(soundAssetKey: string) {
    const gm = GameManagerInstance;
    this.sound.add(soundAssetKey).play({ volume: gm.volume });
  }

  dropItemsOverlap(player: Phaser.Types.Physics.Arcade.GameObjectWithBody, item: Phaser.Types.Physics.Arcade.GameObjectWithBody) {
    const gm = GameManagerInstance;
    const assets = gm.gameAssets;
    const dropItem = item as DropItemSprite;
    const coinScore: number = dropItem.score;
    const damage: number = dropItem.damage;

    const type = player.getData('type');

    if (coinScore && type === 'player') {
      gm.score += coinScore;
      this.createScorePlusAnimation(dropItem.score, dropItem.x, dropItem.y);
      this.playSound(assets.dropItemSound1);
    }
    if (damage && type === 'player') {
      gm.hp -= damage;
      this.playerDamageTimeMs = Date.now();
      this.playSound(assets.lightningSound1);
      if (gm.hp <= 0) {
        this.gameOver();
      }
    }
    // else overlap with ground

    item.destroy();
  }

  init() {
    const gm = GameManagerInstance;
    gm.newGame();
  }

  destroy() {
    this.remainingTimer.destroy();
    this.createCoinTimer.destroy();
  }

  constructor() {
    super({ key: SceneKeys.MainScene });
  }

  /** phaser API */
  preload() {
    const gm = GameManagerInstance;
    const assets = gm.gameAssets;

    this.load.spritesheet(assets.player, assets.player, { frameWidth: 501, frameHeight: this.playerBaseHeight });
    gm.loadGameAssets(this);

    this.itemKeys = [assets.coin1, assets.coin5, assets.coin10, assets.lightning];
  }

  create() {
    const gm = GameManagerInstance;
    const assets = gm.gameAssets;
    this.updateBoundRange();

    this.curScalingMode = window.innerWidth < 880 ? 'mobile' : 'desktop';

    this.platforms = this.physics.add.staticGroup();
    this.platforms.create(gm.worldSize.centerX, gm.worldSize.height - this.groundShift, assets.ground).refreshBody();
    this.platforms.setVisible(false);

    // player --
    const playerHeight = (this.curScalingMode === 'desktop' ? this.playerBaseHeight : this.playerBaseHeight * playerMobileScaleRate) / 2;
    this.player = this.physics.add.sprite(
      gm.worldSize.centerX, gm.worldSize.height - playerHeight - this.groundShift, assets.player);
    if (this.player) {
      this.player.setData('type', 'player');
      this.player.setBounce(0.2);
      this.player.setCollideWorldBounds(true);
      if (this.curScalingMode === 'mobile') {
        this.player.scale = playerMobileScaleRate;
      }

      this.anims.create({
        key: 'left',
        frames: this.anims.generateFrameNumbers(assets.player, { start: 0, end: 0 }),
        frameRate: 2,
        repeat: -1,
      });
      this.anims.create({
        key: 'right',
        frames: this.anims.generateFrameNumbers(assets.player, { start: 1, end: 1 }),
        frameRate: 2,
        repeat: -1,
      });
      this.anims.create({
        key: 'left2',
        frames: [{ key: assets.player, frame: 2 }],
        frameRate: 2,
        repeat: -1,
      });
      this.anims.create({
        key: 'right2',
        frames: [{ key: assets.player, frame: 3 }],
        frameRate: 2,
        repeat: -1,
      });
    }

    this.cursors = this.input.keyboard.createCursorKeys();
    this.keyboard.A = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
    this.keyboard.D = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);
    this.keyboard.Del = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.DELETE);
    this.keyboard.Backspace = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.BACKSPACE);

    this.dropItems = this.physics.add.group();
    this.dropItems.children.iterate(function (child: any) {
      child.setBounceY(Phaser.Math.FloatBetween(0.4, 0.8));
    });
    this.createDropItem();

    this.plusImage = {
      1: assets.plus1,
      5: assets.plus5,
      10: assets.plus10,
    }

    this.physics.add.collider(this.player, this.platforms);
    this.physics.add.overlap(this.player, this.dropItems, this.dropItemsOverlap, undefined, this);
    this.physics.add.overlap(this.platforms, this.dropItems, this.dropItemsOverlap, undefined, this);

    this.createCoinTimer = this.time.addEvent({
      delay: 1100,                // ms
      callback: this.createDropItem,
      //args: [],
      callbackScope: this,
      loop: true
    });
    this.remainingTimer = this.time.addEvent({
      delay: 1000,                // ms
      callback: () => {
        gm.remainingSecond = Math.round(gm.remainingSecond - 1);
      },
      callbackScope: this,
      loop: true
    });
  }

  update() {
    const gm = GameManagerInstance;

    this.updateBoundRange();
    this.updateScaling();
    this.updateCamera();

    // PlayerMove
    this.updatePlayer();

    // Debug -HP
    if (isDevelopment) {
      if ((this.keyboard.Del?.isDown && this.keyboard.LastIsDownDel === false) ||
        (this.keyboard.Backspace?.isDown && this.keyboard.LastIsDownBackspace === false)) {
        gm.hp = gm.hp - 1;
      }
      this.keyboard.LastIsDownDel = this.keyboard.Del?.isDown;
      this.keyboard.LastIsDownBackspace = this.keyboard.Backspace?.isDown;
    }

    if (gm.changeScene(this)) {
      this.destroy();
    }
  }
}
