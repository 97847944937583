
import GameManagerInstance from '@@core/GameManager';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import life0Bar from './life-bar0.png';
import life1Bar from './life-bar1.png';
import life2Bar from './life-bar2.png';
import life3Bar from './life-bar3.png';
import scoreBar from './score-bar.png';
import timerBar from './timer-bar.png';

import classes from './game.module.scss'

const scoreFormatTool = new Intl.NumberFormat('en-US');

export const GameHud = (): JSX.Element => {
  const gm = GameManagerInstance;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, forceRerender] = useState(0);

  useEffect(() => {
    // change state for rerender
    gm.subscribeScore.push(() => forceRerender(x => (x + 1) % 11));
    gm.subscribeHp.push(() => forceRerender(x => (x + 1) % 11));
    gm.subscribeRemainingSecond.push(() => forceRerender(x => (x + 1) % 11));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hpBarImgPath = [
    life0Bar,
    life1Bar,
    life2Bar,
    life3Bar,
  ][gm.hp % 4];

  const timerString = dayjs(0).second(gm.remainingSecond).format('mm:ss');

  return (
    <>
      <img className={classes.hpBar} src={hpBarImgPath} alt="" />
      <section className={classes.timer} >
        <img className={classes.timer__img} src={timerBar} alt="" />
        <div className={classes.timer__text}>{timerString}</div>
      </section>
      <section className={classes.scoreBar}>
        <img className={classes.scoreBar__img} src={scoreBar} alt="" />
        <div className={classes.scoreBar__text}>{scoreFormatTool.format(gm.score)}</div>
      </section>
    </>
  );
};
