import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { SceneKeys } from '@@core/scenes';
import Home from '@@pages/home/Home';
import Instruction from '@@pages/instruction/Instruction';
import AgeMenu from '@@pages/age-menu/AgeMenu';
import GameOver from '@@pages/game-over/GameOver';
import Footer from '@@components/footer/Footer';
import Game from '@@components/game/Game';
import GameManagerInstance from '@@core/GameManager';
import AgeDescription from '@@pages/age-description/AgeDescription';
import ControlTutorials from '@@pages/control-tutorials/ControlTutorials';
import Privacy from '@@pages/privacy/Privacy';
import Share from '@@pages/share/Share';
import { useWindowSize } from '@@core/useWindowSize';
import { SoundButton } from '@@components/sound-button/SoundButton';

import ctbcLogo from './ctbc.png'
import ctbcGreenLogo from './ctbc-g.png'

import classes from './app.module.scss'

const GameStartEffectWrapper = (props: {
  setGaming?: Function;
  children?: ReactNode,
}) => {
  const { children, setGaming } = props;
  useEffect(() => {
    GameManagerInstance.newGame();
    GameManagerInstance.scene = SceneKeys.MainScene;

    setGaming?.(true);

    return () => {
      GameManagerInstance.scene = SceneKeys.PreloadScene;
      setGaming?.(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<>{children}</>)
}

function App() {
  const gm = GameManagerInstance;
  const [gameOver, setGameOver] = useState(false);
  const [gaming, setGaming] = useState(false);

  const [isHomePage, setIsHomePage] = useState(false);

  useWindowSize();

  useEffect(() => {
    gm.subscribeGameOver.push((isGameOver: boolean) => {
      setGameOver(isGameOver);
      setGaming(!isGameOver);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <div className={classes.root}>
        {/** phaser */}
        <Game gaming={gaming} />

        <img className={classes.logo} src={isHomePage ? ctbcGreenLogo : ctbcLogo} alt="" />
        <Footer />

        {/** Modal, Page */}
        <Routes>
          <Route index element={<Home setIsHomePage={setIsHomePage} />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/instruction' element={<Instruction />} />
          <Route path='/ageMenu' element={<AgeMenu />} />
          <Route path='/ageDescription' element={<AgeDescription />} />
          <Route path='/controlTutorials' element={<ControlTutorials />} />
          <Route path='/play' element={
            <GameStartEffectWrapper setGaming={setGaming}>
              {gameOver && <GameOver onClose={() => setGameOver(false)} />}
            </GameStartEffectWrapper>
          } />
          <Route path='/share/*' element={<Share scorePrefix='/share/' />} />
          <Route path='*' element={<Home setIsHomePage={setIsHomePage} />} />
        </Routes>

        <SoundButton />
      </div>
    </BrowserRouter>
  );
}

export default App;
