import * as Phaser from 'phaser';
import { SceneKeys } from '.';
import GameManagerInstance from '@@src/core/GameManager';

export class PreloadScene extends Phaser.Scene {
  constructor() {
    super({ key: SceneKeys.PreloadScene });
  }

  init() {
  }

  preload() {
  }

  create() {
  }

  update() {
    const gm = GameManagerInstance;
    gm.changeScene(this);
  }
}
