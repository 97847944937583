import { AnimateButton } from '@@components/animate-button/AnimateButton';
import Modal from '@@components/modal/Modal';
import { GameAge } from '@@core/gameAge';
import GameManagerInstance from '@@core/GameManager';
import classes from './ageDescription.module.scss'

const titleText: Record<GameAge, string> = {
  Holocene: '史前時代',
  Postclassical: '古代',
  Modern: '現代',
  Future: '未來虛擬世界',
};
const descriptionText: Record<GameAge, string[]> = {
  Holocene: ['貨幣出現前，人們以物易物交換生活必需品。例如用獵捕的動物交換蔬果。'],
  Postclassical: ['貨幣發展出現了自然物貨幣、金屬貨幣、紙鈔。'],
  Modern: ['現代社會發展出金屬幣、紙鈔、塑膠貨幣、數位貨幣等多元類型。'],
  Future: ['新世代有數位貨幣、加密貨幣。存在網路世界， 看得到、摸不到。'],
};
const descriptionImgName: Record<GameAge, string[]> = {
  Holocene: ['恐龍蛋', '肉', '貝殼'],
  Postclassical: ['銅錢', '元寶', '交子'],
  Modern: ['硬幣', '信用卡', '紙鈔'],
  Future: ['加密貨幣', '紅利點數', '行動支付'],
};

const AgeDescription = () => {
  const gm = GameManagerInstance;
  const assets = gm.gameAssets;
  const descriptionImgAssets = [assets.coin1, assets.coin5, assets.coin10];

  return (
    <Modal>
      <Modal.Header className={classes.head}>
        {titleText[gm.age]}
      </Modal.Header>
      <Modal.Body className={classes.body}>
        {descriptionText[gm.age].map(text => (
          <div key={`age:${gm.age}:description`} className={classes.body__text}>{text}</div>
        ))}
        <div className={classes.body__coinGroup}>
          {descriptionImgName[gm.age].map((text, idx) => (
            <div key={text} className={classes.body__coinGroup__coin}>
              <img className={classes.body__coinGroup__coin__icon} src={descriptionImgAssets[idx]} alt="" />
              <div className={classes.body__coinGroup__coin__text}>{text}</div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <AnimateButton
        reactLink='/controlTutorials'
        className={classes.button}>
        我 暸 解 了
      </AnimateButton>
    </Modal>
  );
};

export default AgeDescription;
