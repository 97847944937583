import { AnimateButton } from '@@components/animate-button/AnimateButton';
import Modal from '@@components/modal/Modal';
import { useMobile } from '@@themes/mobile';

import directionImg from './direction-group.png';
import touch from './touch.png';

import classes from './controlTutorials.module.scss'

const ControlTutorials = () => {
  const isMobile = useMobile();

  return (
    <Modal>
      <Modal.Header className={classes.head}>
        操 縱 說 明
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <div className={classes.body__text}>
          {isMobile ? '點擊螢幕來左右控制財神爺方向，以接住貨幣。' : '按下左右鍵控制財神爺方向，以接住貨幣。'}
        </div>
        <img className={classes.body__img} src={isMobile ? touch : directionImg} alt="" />
      </Modal.Body>
      <AnimateButton
        reactLink='/play'
        templateColor='ctbc-green'
        className={classes.button}>
        開 始 挑 戰
      </AnimateButton>
    </Modal>
  );
}

export default ControlTutorials;
