import { MuseumColor } from '@@themes/color';
import clsx from 'clsx';
import { CSSProperties, PropsWithChildren } from 'react';
import classes from './modal.module.scss'
import { ModalBody } from './ModalBody';
import { ModalHeader } from './ModalHeader';

type ModalProps = PropsWithChildren<{
  className?: string;
  opacity?: number;
  color?: string;
}>;

const Modal = (props: ModalProps) => {
  const {
    children,
    className,
    opacity = 0.5,
    color = MuseumColor['--museum-color-gray-3'],
  } = props;

  return (
    <>
      <div className={classes.background} style={{
        opacity: opacity,
        backgroundColor: color,
      } as CSSProperties} />
      <article className={clsx(
        classes.front,
        className,
      )}>
        {children}
      </article>
    </>
  );
};


Modal.Header = ModalHeader;
Modal.Body = ModalBody;

export default Modal;