import clsx from "clsx";
import { PropsWithChildren } from "react";
import classes from './modal.module.scss'

type ModalHeaderProps = PropsWithChildren<{
  className?: string;
}>;

export const ModalHeader = (props: ModalHeaderProps) => {
  const {
    children,
    className,
  } = props;
  return (
    <header className={clsx(classes.head, className)}>
      {children}
    </header>
  );
};
