import { AnimateButton } from '@@components/animate-button/AnimateButton';
import Modal from '@@components/modal/Modal';
import classes from './privacy.module.scss';

const Privacy = () => {
  return (
    <Modal>
      <Modal.Header className={classes.head}>
        隱私權保護政策
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <div className={classes.bodyText}>
          本網站隱私權保護政策內容遵守政府的隱私權法律相關規定，包括本網站處理您使用網站服務時，所收集到的個人識別資料。 更多詳細規定請見中國信託「
          <a
            className={classes.link}
            href='https://www.ctbcbank.com/twrbo/zh_tw/index/ctbc_privacypolicy.html'
            target="_blank"
            rel="noreferrer noopener">
            隱私權政策
          </a>
          」。
        </div>
      </Modal.Body>
      <AnimateButton
        reactLink='/home'
        templateColor='ctbc-green'
        className={classes.button}>
        我 暸 解 了
      </AnimateButton>
    </Modal>
  );
}

export default Privacy;
