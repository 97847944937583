
export enum MuseumColorName {
  'gray-1',
  'gray-2',
  'gray-3',
  'black',
  'white',
  'ctbc-red-1',
  'ctbc-red-2',
  'ctbc-green-1',
  'ctbc-green-2',
  'ctbc-green-3',
  'green-1',
  'green-2',
  'green-3',
  'green-4',
  'blue-1',
  'blue-2',
  'blue-3',
  'blue-4',
  'yellow-1',
  'yellow-2',
  'yellow-3',
  'orange-1',
  'orange-2',
  'orange-3',
  'brown-1',
  'brown-2',
  'brown-3',
  'brown-4',
  'red-1',
  'red-2',
  'red-3',
  'red-4',
}

export const MuseumColor: Record<string, string> = {
  "--museum-color-white": "#ffffff",
  "--museum-color-gray-3": "#5e6066",
}

const cssVarPrefix = '--museum-color-';
const museumColorValues = Object.values(MuseumColorName);

export const MuseumColorToCssVar = (color: MuseumColorName) => `${cssVarPrefix}${museumColorValues[color]}`;
